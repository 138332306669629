import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import alertSocket from 'utils/alertSocket';
import sockets from 'utils/sockets';
import priceFormatter from 'utils/priceFormatter';
import createMediumURL from 'utils/createMediumURL';
import parseMessage from 'utils/parseMessage';

import WithData from 'containers/WithData';
import Element from 'styles/Element';
import CustomCode from 'components/CustomCode';
import Scene from './styles/Scene';
import elements from './elements';

const ANIMATION_DIRECTION_IDLE = '';
const ANIMATION_DIRECTION_IN = 'in';
const ANIMATION_DIRECTION_DURING = 'during';
const ANIMATION_DIRECTION_OUT = 'out';

/**
 * Generuje style z opcji elementu
 *
 * @param {Object} elementOptions
 * @returns {Object}
 */
const generateStyles = elementOptions => {
  const { styles, position, size } = elementOptions;

  const positions = {
    left: position ? position.x : 35,
    top: position ? position.y : 35,
  };

  const combinedStyles = {
    ...styles,
    ...positions,
  };

  size && Object.assign(combinedStyles, size);

  return combinedStyles;
};

const Donation = ({ donation, style, className }) => (
  <Element className="tpl-price" style={style}>
    <Element shadow>1 000 000,00 zł</Element>
    <Element className={className} style={{ display: 'inline-block' }}>
      {donation}
    </Element>
  </Element>
);

Donation.propTypes = {
  donation: PropTypes.string.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
};

const DonorName = ({ name, style, className }) => (
  <Element className="tpl-nickname" style={style}>
    <Element shadow>================================</Element>
    <Element className={className} style={{ display: 'inline-block' }}>
      {name}
    </Element>
  </Element>
);

DonorName.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
};

const DonorMessage = ({ rawMessage, ...props }) => (
  <Element {...props}>
    <div dangerouslySetInnerHTML={{ __html: parseMessage(rawMessage) }} />
  </Element>
);

DonorMessage.propTypes = {
  rawMessage: PropTypes.string.isRequired,
};

const VisualObject = ({ type, ...props }) => {
  if (type === 'sonata.media.provider.image' || type === 'sonata.media.provider.user_image') {
    return <img alt="" {...props} />;
  }

  return <video autoPlay muted {...props} />;
};

VisualObject.propTypes = {
  type: PropTypes.string.isRequired,
};

const AdditionalText = ({ text, ...props }) => <Element {...props}>{text}</Element>;

AdditionalText.propTypes = {
  text: PropTypes.string.isRequired,
};

const Alert = props => {
  const { alert, alertTemplate, animationDirection, media, getMediumProps } = props;
  const { elementsOptions } = alertTemplate.config;

  function getDirectionAnimations() {
    const {
      config: { animation: sceneAnimation },
    } = alertTemplate;

    const animations = {
      scene: null,
      elements: {
        visualObject: null,
        price: null,
        username: null,
        usernameAction: null,
        message: null,
        visualObject1: null,
        visualObject2: null,
        visualObject3: null,
        usernameAction1: null,
        usernameAction2: null,
        usernameAction3: null,
      },
    };

    const assembleAnimationClass = str =>
      `animated ${str}${animationDirection === ANIMATION_DIRECTION_DURING ? ' infinite' : ''}`;

    // Określamy animacje sceny
    if (sceneAnimation[animationDirection]) {
      animations.scene = assembleAnimationClass(sceneAnimation[animationDirection]);
    } else if (animationDirection === ANIMATION_DIRECTION_OUT) {
      animations.scene = assembleAnimationClass('noAnimationEnd');
    }

    // Jeśli scena nie jest animowana, to określamy animacje elementów
    if (!animations.scene || animations.scene === 'animated noAnimationEnd') {
      elements.forEach(elementName => {
        let animation = null;

        if (elementsOptions[elementName] && elementsOptions[elementName].animation) {
          animation = elementsOptions[elementName].animation[animationDirection];
        }

        if (animation) {
          animations.elements[elementName] = assembleAnimationClass(animation);
        } else if (animationDirection === ANIMATION_DIRECTION_OUT) {
          animations.scene = assembleAnimationClass('noAnimationEnd');
        }
      });
    }

    return animations;
  }

  const animations = getDirectionAnimations();

  /**
   * Zwraca sformatowaną sumę dotacji w zależności od ustawień
   * wyświetlenia kwoty z czy bez komisji
   *
   * @return {string}
   */
  const getDonation = () => {
    const amount = alertTemplate.config.amountWithoutCommission
      ? alert.amount - alert.commission
      : alert.amount;

    return priceFormatter(amount, true, 'default');
  };

  return (
    <Scene className={classnames(animations.scene)}>
      {/* Wizualne obiekty */}
      {[1, 2, 3, 4].map((key, index) => {
        const elementKey = `visualObject${index !== 0 ? index : ''}`;
        const elementOptions = elementsOptions[elementKey];

        if (!elementOptions || !elementOptions.isVisible || !media[elementOptions.mediumId])
          return null;

        const { src, type } = getMediumProps(elementOptions.mediumId);
        const elementId = `tpl-visual-object-${key}`;
        return (
          <VisualObject
            key={elementId}
            src={src}
            type={type}
            style={generateStyles(elementOptions)}
            className={`${elementId} ${classnames(animations.elements[elementKey])}`}
          />
        );
      })}

      {/* Rozmiar dotacji */}
      {elementsOptions.price.isVisible && (
        <Donation
          donation={getDonation()}
          style={generateStyles(elementsOptions.price)}
          className={classnames(animations.elements.price)}
        />
      )}

      {/* Imę dawcy */}
      {elementsOptions.username.isVisible && (
        <DonorName
          name={alert.nickname}
          style={generateStyles(elementsOptions.username)}
          className={classnames(animations.elements.username)}
        />
      )}

      {/* Teksty */}
      {[1, 2, 3, 4].map((key, index) => {
        const elementKey = `usernameAction${index !== 0 ? index : ''}`;
        const elementOptions = elementsOptions[elementKey];

        if (!elementOptions || !elementOptions.isVisible) return null;

        const elementId = `tpl-text-${key}`;
        return (
          <AdditionalText
            key={elementId}
            text={elementOptions.value}
            style={generateStyles(elementOptions)}
            className={`${elementId} ${classnames(animations.elements[elementKey])}`}
          />
        );
      })}

      {/* Wiadomość */}
      {elementsOptions.message.isVisible && (
        <DonorMessage
          rawMessage={alert.message}
          style={generateStyles(elementsOptions.message)}
          className={`tpl-message ${classnames(animations.elements.message)}`}
        />
      )}

      <CustomCode template={alertTemplate} />
    </Scene>
  );
};

Alert.propTypes = {
  alert: PropTypes.instanceOf(Object).isRequired,
  alertTemplate: PropTypes.instanceOf(Object).isRequired,
  animationDirection: PropTypes.string.isRequired,
  media: PropTypes.instanceOf(Object).isRequired,
  getMediumProps: PropTypes.instanceOf(Function).isRequired,
};

const TipAlert = props => {
  const { media, configuration, templates, messages, insertMessage, clearLatestMessage } = props;

  const [stateConfig, setStateConfig] = useState(configuration.config);
  const [stateTemplates, setStateTemplates] = useState(templates);
  const [stateMessages, setStateMessages] = useState(messages);
  const [alertsSoundDisabled, setAlertsSoundDisabled] = useState(
    configuration.config.alertsSoundDisabled || false,
  );

  const [isBusy, setIsBusy] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [animationDirection, setAnimationDirection] = useState(ANIMATION_DIRECTION_IDLE);

  const [activeAlert, setActiveAlert] = useState();
  const [alertTemplate, setAlertTemplate] = useState();

  const playedSound = useRef(null);

  function reset() {
    setAnimationDirection(ANIMATION_DIRECTION_IDLE);
    setIsBusy(false);
  }

  function getDisplaySettingsValue(type) {
    const { displaySettings } = stateConfig;

    let returnSettings = displaySettings.defaults[type];
    let thresholds = displaySettings.tresholds[type];

    if (!thresholds.length && type === 'synth') {
      return null;
    }

    // Sortujemy od największej do najmniejszej
    thresholds = thresholds.sort((a, b) => b.amount - a.amount);
    for (let i = 0; i < thresholds.length; i += 1) {
      if (activeAlert.amount >= thresholds[i].amount) {
        returnSettings = thresholds[i];
        break;
      }
    }

    if (['sounds', 'synth'].includes(type)) {
      return returnSettings;
    }

    return stateTemplates.find(item => item.id === returnSettings.templateId) || returnSettings;
  }

  /**
   * Zwraca Promise, który odtwarza audio ze wskazanej
   * ścieżki i się kończy, gdy audio skończy się odtwarzać lub
   * wystąpi błąd.
   *
   * @param {string} url      Ścieżka do pliku audio
   * @param {number} [volume] Głośność, ma być od 0 do 1
   * @returns {Promise<void>}
   */
  async function playAudio(url, volume = 1) {
    return new Promise(resolve => {
      const audio = new Audio(url);
      audio.volume = alertsSoundDisabled ? 0 : volume;
      audio.autoplay = true;

      playedSound.current = audio;

      const finalize = () => {
        audio.removeEventListener('ended', finalize);
        audio.removeEventListener('error', finalize);
        audio.remove();
        resolve();
      };

      audio.addEventListener('ended', finalize);
      audio.addEventListener('error', finalize);
    });
  }

  /**
   * Plays element's Text-To-Speech audio.
   *
   * Note: old version alerts featured tts name suffix, while new version
   * has no suffixes, that's why this function searches the attribute by its
   * beginning. Thins can be removed once version with suffixes are fully
   * removed from the project.
   *
   * @param currentMessage {Object} Message object
   * @param elementName {string} Element name used in the key id
   * @param volume {number} volume to be played
   * @return {Promise<void>}
   */
  const playMessageElementAudio = async (currentMessage, elementName, volume) => {
    const ttsKey = `tts_${elementName}`;
    const targetKey = Object.keys(currentMessage).find(key => key.startsWith(ttsKey));
    if (targetKey && currentMessage[targetKey])
      await playAudio(createMediumURL(currentMessage[targetKey]), volume);
  };

  /**
   * Rozpoczyna kolejkę dźwięków. Po kolei odtwarza
   * wstępne audio, po czym czytanie tekstów.
   * Zwraca Promise czy były odtworzone dźwięki, czy nie.
   *
   * @return {Promise<number>}
   */
  async function queueAudio() {
    setIsPlayingAudio(true);

    const textToSpeechConfig = getDisplaySettingsValue('synth') || { voiceType: 'DISABLED' };
    const audioConfig = getDisplaySettingsValue('sounds');
    const { src: mediumUrl } = getMediumProps(audioConfig.mediumId);

    // Jeżeli komunikat nie posiada dźwięku oraz syntezatora mowy,
    // to zwracamy 5 sekund do końca dźwięku
    if (!mediumUrl && textToSpeechConfig.voiceType === 'DISABLED' && !activeAlert.audio_url) {
      return 5;
    }

    if (mediumUrl) {
      await playAudio(mediumUrl, audioConfig.volume);
    }

    if (
      activeAlert.audio_url &&
      stateConfig.voiceMessages.enable &&
      stateConfig.voiceMessages.amount <= activeAlert.amount
    ) {
      await playAudio(activeAlert.audio_url);
    }

    if (textToSpeechConfig.voiceType !== 'DISABLED') {
      await playTextToSpeech(textToSpeechConfig);
    }

    return 7;
  }

  async function playTextToSpeech(textToSpeechConfig) {
    const { options } = textToSpeechConfig;

    const playElementAudio = elementName =>
      playMessageElementAudio(activeAlert, elementName, options.volume);

    if (options.readNickname) await playElementAudio('nickname');
    if (options.readAmount) await playElementAudio('amount');
    if (options.readMessage) await playElementAudio('message');
  }

  /**
   * Zaznacza koniec odtwarzania audio po upływie
   * określonego czasu w sekundach
   *
   * @param {number} seconds ilość w sekundach
   * @returns {void}
   */
  function finishPlaybackIn(seconds = 0) {
    setTimeout(() => {
      setIsPlayingAudio(false);
    }, seconds * 1000);
  }

  /**
   * Zwraca url medium i jego typ
   *
   * @param {number | string} mediumId
   * @returns {{src: string, type: string}}
   */
  function getMediumProps(mediumId) {
    if (!mediumId || !media[mediumId]) return { src: '', type: '' };

    return {
      src: createMediumURL(media[mediumId].reference.url, media[mediumId].default),
      type: media[mediumId].reference.provider,
    };
  }

  function exitMessage() {
    setAnimationDirection(ANIMATION_DIRECTION_OUT);
  }

  function mute(isMuted) {
    setAlertsSoundDisabled(isMuted);

    if (playedSound.current && isMuted) {
      playedSound.current.volume = 0;
    }
  }

  function skipMessage() {
    reset();
    clearLatestMessage();

    if (playedSound.current) {
      playedSound.current.pause();
      playedSound.current.remove();
      playedSound.current = null;
    }
  }

  function handleCommand({ command, data }) {
    switch (command) {
      case 'setAlertsSoundDisabled':
        mute(data);
        break;
      case 'skipMessage':
        skipMessage();
        break;
      default:
        break;
    }
  }

  // Włącz soket'y po zamontowaniu i wyłącz po jego odmontowaniu.
  useEffect(() => {
    const apiSockets = sockets(
      () => {},
      () => {},
      () => {},
      () => {},
      () => {},
      () => {},
      handleCommand,
    );
    const incomingAlertSocket = alertSocket(insertMessage);

    apiSockets.on();
    incomingAlertSocket.on();

    return () => {
      apiSockets.off();
      incomingAlertSocket.off();
    };
  }, []);

  // Pobieramy stan z propsów.
  useEffect(() => {
    setStateConfig(configuration.config);
    setStateTemplates(templates);
    setStateMessages(messages);
  }, [configuration, templates, messages]);

  /*
    Śledzimy za tym, czy widżej wyświetla wiadomość
    oraz, czy jest jeszcze wiadomości do wyświetlenia,
    w wypadku których odpalamy wyświetlenie kolejnej wiadomości.
  */
  useEffect(() => {
    if (!isBusy && stateMessages.length > 0) {
      setActiveAlert(stateMessages[0].message);
    }
  }, [isBusy, stateMessages]);

  useEffect(() => {
    if (activeAlert) {
      const targetTemplate = getDisplaySettingsValue('templates');
      setIsBusy(true);
      setAlertTemplate(targetTemplate);
      setAnimationDirection(ANIMATION_DIRECTION_IN);
      setIsVisible(true);
      queueAudio().then(addSeconds =>
        finishPlaybackIn(targetTemplate.config.animation.autoDisplayDuration ? addSeconds : 0),
      );
    }
  }, [activeAlert]);

  useEffect(() => {
    let timeout;

    if (alertTemplate) {
      const animation = { ...alertTemplate.config.animation };
      const offset = 0 + !!animation[ANIMATION_DIRECTION_IN] + !!animation[ANIMATION_DIRECTION_OUT];

      const initAnimation = (direction, callback) => {
        if (animation[direction]) {
          timeout = setTimeout(() => callback(), offset ? 1000 : 500);
        } else callback();
      };

      if (animationDirection === ANIMATION_DIRECTION_IN) {
        initAnimation(ANIMATION_DIRECTION_IN, () => {
          setAnimationDirection(ANIMATION_DIRECTION_DURING);
        });
      }

      if (animationDirection === ANIMATION_DIRECTION_DURING) {
        if (!animation.autoDisplayDuration && !!animation.displayDuration) {
          const duration =
            offset > animation.displayDuration ? 0 : (animation.displayDuration || 7) - offset;

          if (duration) {
            timeout = setTimeout(() => exitMessage(), duration * 1000);
          } else {
            exitMessage();
          }
        }
      }

      if (animationDirection === ANIMATION_DIRECTION_OUT) {
        initAnimation(ANIMATION_DIRECTION_OUT, () => setIsVisible(false));
      }
    }

    return () => clearTimeout(timeout);
  }, [animationDirection, alertTemplate]);

  useEffect(() => {
    // Działania na ukończenie odtwarzania audio
    if (!isPlayingAudio) {
      // Jeżeli wiadomość nie jest już widoczna, to resetujemy widok
      if (!isVisible) {
        reset();
        clearLatestMessage();
      }
      // W razie braku czasu wyświetlania wiadomości chowamy wiadomość
      if (alertTemplate && isVisible) {
        const animation = { ...alertTemplate.config.animation };
        if (animation.autoDisplayDuration || !animation.displayDuration) {
          exitMessage();
        }
      }
    }
  }, [isVisible, isPlayingAudio, alertTemplate]);

  if (!isBusy || !isVisible || !activeAlert || !alertTemplate) return null;

  return (
    <Alert alert={activeAlert} {...{ alertTemplate, animationDirection, media, getMediumProps }} />
  );
};

TipAlert.propTypes = {
  insertMessage: PropTypes.instanceOf(Function).isRequired,
  configuration: PropTypes.instanceOf(Object).isRequired,
  templates: PropTypes.instanceOf(Array).isRequired,
  messages: PropTypes.instanceOf(Array).isRequired,
  media: PropTypes.instanceOf(Object).isRequired,
  clearLatestMessage: PropTypes.instanceOf(Function).isRequired,
};

export default withRouter(WithData(TipAlert));
